<template>
  <div>
    <v-card elevation="0">
      <div class="text-subtitle-2 pa-1 blue--text blue lighten-5">
        過去5日間の処理件数
      </div>
      <v-container fluid>
        <v-row>
          <v-col col="12" md="6">
            <v-card elevation="0">
              <v-card-title>
                <h4>自動更新</h4>
              </v-card-title>
              <!-- テーブル -->
              <v-data-table
                :headers="headers"
                :items="auto_desserts"
                item-key="log_date"
                :mobile-breakpoint="0"
                :items-per-page="50"
                :footer-props="{
                  'items-per-page-options': [50, 100, -1],
                  'items-per-page-text': '表示件数',
                }"
                :hide-default-footer="true"
                class="table_border"
              >
                <!-- eslint-disable-next-line -->
                <template v-slot:footer.page-text="props">
                  {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
                    props.itemsLength
                  }}件
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.max_task_time="{ item }">
                  {{ item.max_task_time }}秒
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.avg_task_time="{ item }">
                  {{ item.avg_task_time }}秒
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.result_ok="{ item }">
                  <v-icon class="green--text"> mdi-check </v-icon>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.result_ng="{ item }">
                  <v-icon class="red--text"> mdi-close </v-icon>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.result_other="{ item }">
                  <v-icon> mdi-help-circle-outline </v-icon>
                </template>
                <template v-slot:no-data> 表示するデータがありません </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col col="12" md="6">
            <v-card elevation="0">
              <v-card-title>
                <h4>手動更新</h4>
              </v-card-title>
              <!-- テーブル -->
              <v-data-table
                :headers="headers"
                :items="manual_desserts"
                item-key="log_date"
                :mobile-breakpoint="0"
                :items-per-page="50"
                :footer-props="{
                  'items-per-page-options': [50, 100, -1],
                  'items-per-page-text': '表示件数',
                }"
                :hide-default-footer="true"
                class="table_border"
              >
                <!-- eslint-disable-next-line -->
                <template v-slot:footer.page-text="props">
                  {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
                    props.itemsLength
                  }}件
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.max_task_time="{ item }">
                  {{ item.max_task_time }}秒
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.avg_task_time="{ item }">
                  {{ item.avg_task_time }}秒
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.result_ok="{ item }">
                  <v-icon class="green--text"> mdi-check </v-icon>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.result_ng="{ item }">
                  <v-icon class="red--text"> mdi-close </v-icon>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.result_other="{ item }">
                  <v-icon> mdi-help-circle-outline </v-icon>
                </template>
                <template v-slot:no-data> 表示するデータがありません </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <div class="text-subtitle-2 mt-6 pa-1 blue--text blue lighten-5">
        過去24時間の処理件数
      </div>
      <v-container fluid>
        <v-row>
          <v-col col="12" md="6">
            <v-card elevation="0">
              <v-card-title>
                <h4>自動更新</h4>
              </v-card-title>
              <!-- テーブル -->
              <v-data-table
                :headers="tc_headers"
                :items="tc_auto_desserts"
                item-key="task_server_id"
                :mobile-breakpoint="0"
                :items-per-page="50"
                :footer-props="{
                  'items-per-page-options': [50, 100, -1],
                  'items-per-page-text': '表示件数',
                }"
                :hide-default-footer="true"
                class="table_border"
              >
                <!-- eslint-disable-next-line -->
                <template v-slot:footer.page-text="props">
                  {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
                    props.itemsLength
                  }}件
                </template>
                <template v-slot:no-data> 表示するデータがありません </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col col="12" md="6">
            <v-card elevation="0">
              <v-card-title>
                <h4>手動更新</h4>
              </v-card-title>
              <!-- テーブル -->
              <v-data-table
                :headers="tc_headers"
                :items="tc_manual_desserts"
                item-key="task_server_id"
                :mobile-breakpoint="0"
                :items-per-page="50"
                :footer-props="{
                  'items-per-page-options': [50, 100, -1],
                  'items-per-page-text': '表示件数',
                }"
                :hide-default-footer="true"
                class="table_border"
              >
                <!-- eslint-disable-next-line -->
                <template v-slot:footer.page-text="props">
                  {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
                    props.itemsLength
                  }}件
                </template>
                <template v-slot:no-data> 表示するデータがありません </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <div class="text-subtitle-2 mt-6 pa-1 blue--text blue lighten-5">
        現在予約されている更新コンテンツ一覧
      </div>
      <v-container fluid>
        <v-row>
          <v-col col="12" md="12">
            <v-card elevation="0">
              <v-card-title>
                <h4>次回更新時刻リスト</h4>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="update_status_search"
                  append-icon="mdi-magnify"
                  label="（キーワード検索）"
                  single-line
                  hide-details
                  clearable
                  dense
                ></v-text-field>
              </v-card-title>
              <!-- テーブル -->
              <v-data-table
                :headers="update_status_headers"
                :items="update_status_desserts"
                :search="update_status_search"
                item-key="update_content_id"
                :mobile-breakpoint="0"
                :items-per-page="50"
                :footer-props="{
                  'items-per-page-options': [50, 100, -1],
                  'items-per-page-text': '表示件数',
                }"
                class="table_border"
              >
                <!-- eslint-disable-next-line -->
                <template v-slot:footer.page-text="props">
                  {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
                    props.itemsLength
                  }}件
                </template>
                <template v-slot:no-data> 表示するデータがありません </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";

export default {
  setup(props, ctx) {
    const ResultLogsRepository = repositoryFactory.get("resultLogs");
    const UpdateContentsRepository = repositoryFactory.get("updateContents");
    const state = reactive({
      search: "",
      headers: [
        {
          text: "日付",
          value: "log_date",
          sortable: true,
          width: 100,
        },
        {
          text: " 件数",
          value: "task_count",
          sortable: true,
          align: "center",
        },
        {
          text: "MAX",
          value: "max_task_time",
          sortable: true,
          align: "center",
        },
        {
          text: "AVG",
          value: "avg_task_time",
          sortable: true,
          align: "center",
        },
        {
          text: "成功",
          value: "result_ok",
          sortable: true,
          align: "center",
        },
        {
          text: "エラー",
          value: "result_ng",
          sortable: true,
          align: "center",
        },
        {
          text: "その他",
          value: "result_other",
          sortable: true,
          align: "center",
        },
      ],
      desserts: [],
      auto_desserts: [],
      manual_desserts: [],
      tc_headers: [
        {
          text: "サーバーID",
          value: "task_server_id",
          sortable: true,
          align: "center",
        },
        {
          text: " ステータス",
          value: "result",
          sortable: true,
        },
        {
          text: "件数",
          value: "count",
          sortable: true,
          align: "center",
        },
      ],
      tc_auto_desserts: [],
      tc_manual_desserts: [],
      update_status_search: "",
      update_status_headers: [
        {
          text: "ユーザー",
          value: "user_name",
          sortable: true,
          width: 100,
        },
        {
          text: "店舗名",
          value: "shop_name",
          sortable: true,
        },
        {
          text: "サイト名",
          value: "site_name",
          sortable: true,
        },
        {
          text: "種別",
          value: "content_name",
          sortable: true,
        },
        {
          text: "コンテンツ",
          value: "site_content_name",
          sortable: true,
        },
        {
          text: "次回更新",
          value: "next_update",
          sortable: true,
        },
        {
          text: "サーバID",
          value: "task_server_id",
          sortable: true,
          align: "center",
        },
      ],
      update_status_desserts: [],
    });

    // 初期化処理(同期処理)
    const init = async () => {};

    // 初期化する
    init();

    // 初期化処理
    onMounted(async () => {
      // ローディング表示
      store.dispatch("loadingIcon/showIcon");

      // ログ情報取得
      await ResultLogsRepository.admin_list_date_home()
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach((key) => {
              const tmp = response.data[key];
              tmp.log_category = tmp.log_category.replace("lib.task.", "");
              if (tmp.log_category == "update_timer") {
                state.auto_desserts.push(tmp);
              } else if (tmp.log_category == "manual_update") {
                state.manual_desserts.push(tmp);
              }
            });
          }
        })
        .catch((error) => {
          throw "ERROR:ResultLogsRepository.admin_list_date (" + error + ")";
        });

      // ログ情報取得(task_server_count)
      await ResultLogsRepository.admin_task_server_count()
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach((key) => {
              const tmp = response.data[key];
              tmp.log_category = tmp.log_category.replace("lib.task.", "");
              if (tmp.log_category == "update_timer") {
                state.tc_auto_desserts.push(tmp);
              } else if (tmp.log_category == "manual_update") {
                state.tc_manual_desserts.push(tmp);
              }
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:ResultLogsRepository.admin_task_server_count (" + error + ")"
          );
        });

      // 更新ステータスリスト取得
      await UpdateContentsRepository.admin_update_content_status()
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach((key) => {
              const tmp = response.data[key];
              tmp.next_update = tmp.next_update
                ? utilDate.getDatetimeFormatLongDisplay(
                    utilDate.convertSplitStrToDate(tmp.next_update)
                  )
                : "";
              state.update_status_desserts.push(tmp);
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:UpdateContentsRepository.admin_update_content_status (" +
            error +
            ")"
          );
        });

      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_task_count {
  text-align: right !important;
}
.table_border {
  border: 1px solid #ddd !important;
  border-radius: 0.5em !important;
}
</style>
